import React, { useCallback, useState } from 'react';

import { Box, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';

import { EndOfPageCard } from '@confluence/page-card/entry-points/EndOfPageCard';
import type { GlobalPageCard } from '@confluence/page-card';

const CarouselStyle = xcss({
	position: 'relative',
	height: '136px',
	paddingRight: 'space.500',
	paddingLeft: 'space.500',
	overflow: 'hidden',
});

const shadowOverlayStyle = xcss({
	position: 'absolute',
	top: 'space.0',
	bottom: 'space.0',
	width: '70px',
	pointerEvents: 'none',
});

const rightOverlayStyle = xcss({
	background: `linear-gradient(to right, rgba(255,255,255,0) 0%, ${token('utility.elevation.surface.current')} 100%)`,
	right: 'space.500',
});

const leftOverlayStyle = xcss({
	background: `linear-gradient(to left, rgba(255,255,255,0) 0%, ${token('utility.elevation.surface.current')} 100%)`,
	left: 'space.500',
});

const CarouselContainerStyle = xcss({
	width: '100%',
	overflowX: 'clip', // Allow content to overflow for partial next card
});

const CarouselContentStyle = xcss({
	display: 'flex',
	transition: 'transform 0.5s ease',
});

const CardWrapperStyle = xcss({
	flexBasis: '100%',
	flex: '1',
	width: '0',
	height: '128px',
	marginRight: 'space.200',
});

const PaginationButtonStyle = xcss({
	position: 'absolute',
	top: '50%',
	transform: 'translateY(-50%)',
	background: 'none',
	border: 'none',
	font: 'font.heading.large',
	cursor: 'pointer',
});

const PrevStyle = xcss({
	left: '0',
});

const NextStyle = xcss({
	right: '0',
});

export type EndOfPageRecCarouselProps = {
	pageCardData: GlobalPageCard[];
	pageRecStrategy?: string[][];
	entityId: string;
};

export const EndOfPageRecCarousel = ({
	pageCardData,
	pageRecStrategy,
	entityId,
}: EndOfPageRecCarouselProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [currentIndex, setCurrentIndex] = useState(0);
	const cardsPerPage = 3;
	const cardWidth = 240;
	const cardMargin = 20;
	const totalPages = Math.ceil(pageCardData.length / cardsPerPage);

	const goToPrevious = useCallback(() => {
		setCurrentIndex((prevIndex) => (prevIndex === 0 ? totalPages - 1 : prevIndex - 1));
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'leftCarousel',
				source: 'endOfPageRecommendations',
				attributes: {
					pos: currentIndex,
					contentId: entityId,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, currentIndex, entityId, totalPages]);

	const goToNext = useCallback(() => {
		setCurrentIndex((prevIndex) => (prevIndex === totalPages - 1 ? 0 : prevIndex + 1));
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'rightCarousel',
				source: 'endOfPageRecommendations',
				attributes: {
					pos: currentIndex,
					contentId: entityId,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, currentIndex, entityId, totalPages]);

	return (
		<Box xcss={CarouselStyle}>
			<Box
				onClick={goToPrevious}
				xcss={[PaginationButtonStyle, PrevStyle]}
				testId="endOfPageRecommendations-prev-page-button"
			>
				&#8249;
			</Box>
			{currentIndex != 0 && (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				<Box xcss={[shadowOverlayStyle, leftOverlayStyle]} style={{ zIndex: 1 }} />
			)}
			<Box xcss={CarouselContainerStyle}>
				<Box
					style={{
						width: `${(cardWidth + cardMargin) * pageCardData.length - cardMargin}px`,
						transform: `translateX(calc(-${currentIndex} * (100% / ${totalPages})))`,
					}}
					xcss={CarouselContentStyle}
					testId="endOfPageRecommendations-cards-wrapper"
				>
					{pageCardData.map((node, i) => (
						<Box xcss={CardWrapperStyle} key={`${node.id}-${i}`}>
							<EndOfPageCard
								data={node}
								ranking={i}
								analyticsData={{
									source: 'endOfPageRecommendations',
									attributes: {
										strategy: pageRecStrategy ? pageRecStrategy[i % pageRecStrategy.length] : null,
										mainContentId: entityId,
									},
								}}
							/>
						</Box>
					))}
				</Box>
			</Box>
			{currentIndex != totalPages - 1 && (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				<Box xcss={[shadowOverlayStyle, rightOverlayStyle]} style={{ zIndex: 1 }} />
			)}
			<Box
				onClick={goToNext}
				xcss={[PaginationButtonStyle, NextStyle]}
				testId="endOfPageRecommendations-next-page-button"
			>
				&#8250;
			</Box>
		</Box>
	);
};
