import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N200, N40 } from '@atlaskit/theme/colors';

type EndOfPageRecContainerProps = {
	maxWidth: string;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EndOfPageRecContainer = styled.div<EndOfPageRecContainerProps>({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	paddingBottom: token('space.250', '20px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: (props) => props.maxWidth,
	margin: 'auto',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EndOfPageRecPlaceholderContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	paddingTop: token('space.600', '48px'),
	paddingBottom: token('space.250', '20px'),
	maxWidth: '760px',
	margin: 'auto',
	width: '100%',
	height: '120px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EndOfPageRecHeader = styled.div({
	font: token('font.heading.xsmall'),
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	paddingBottom: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeaderFrontContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.050', '4px'),
	paddingRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeaderLineContainer = styled.div({
	display: 'flex',
	backgroundColor: token('color.background.neutral', N40),
	height: '1px',
	width: 0,
	flex: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InfoContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.150', '12px'),
	fontWeight: token('font.weight.regular'),
	maxWidth: '328px',
	lineHeight: '20px',
	padding: `${token('space.200', '16px')} ${token('space.300', '24px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FeedbackContainer = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DropdownContainer = styled.div({
	display: 'flex',
	paddingLeft: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PageCardsContainer = styled.div({
	display: 'flex',
	height: '112px',
	justifyContent: 'space-between',
	flexFlow: 'row wrap',
	gap: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EndOfPageRecAdminConfigContainer = styled.div<{ isNav4Enabled: boolean }>({
	display: 'flex',
	flexDirection: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingLeft: (props) => (props.isNav4Enabled ? undefined : token('space.250', '20px')),
	paddingRight: token('space.800', '64px'),
	gap: token('space.300', '24px'),
	font: token('font.body'),
	maxWidth: '885px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EndOfPageRecHideControlContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EndOfPageRecHideToggleContainer = styled.div({
	color: token('color.text.subtle', N200),
	display: 'flex',
	alignItems: 'center',
	gap: token('space.150', '12px'),
	paddingLeft: token('space.300', '24px'),
	font: token('font.heading.xxsmall'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EndOfPageRecDefaultContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.200', '16px'),
	paddingLeft: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EndOfPageRecDefaultTitleContainer = styled.div({
	color: token('color.text.subtle', N200),
	font: token('font.heading.xxsmall'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SpaceFeedbackButtonWrapper = styled.span({
	marginLeft: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FeedbackFormQuestion = styled.p({
	font: token('font.heading.xxsmall'),
	color: token('color.text.subtle', N200),
	paddingBottom: token('space.100', '8px'),
});
