import { styled } from '@compiled/react';

import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';

import { type ColorValues } from '../../constants';

type ContainerProps = {
	isIconOnly: boolean;
	colorsConfig: ColorValues;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DropdownLabel = styled.label({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body', fontFallback.body.medium),
	fontWeight: token('font.weight.semibold', '600'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const NameLabel = styled.span({
	marginLeft: token('space.100', '8px'),
	color: token('color.text.subtle', 'inherit'),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const ContainerLabel = styled.div<{ definitionExists?: boolean }>((props) => ({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	marginBottom: props.definitionExists ? token('space.100', '8px') : token('space.0', '0px'),
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const ContainerIcon = styled.span<ContainerProps>((props) => ({
	display: 'inline-flex',
	maxWidth: '100%',
	whiteSpace: 'nowrap',
	width: 'auto',
	alignItems: 'center',
	padding: token('space.025', '2px'),
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	paddingRight: props.isIconOnly ? token('space.025', '2px') : token('space.050', '4px'),
	borderRadius: token('border.radius', '3px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: props.colorsConfig.fontColor,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	background: props.colorsConfig.bgColor,
	userSelect: 'none',
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Name = styled.span({
	display: 'flex',
	alignItems: 'center',
	font: token(
		'font.body',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		fontFallback.body.medium,
	),
	fontWeight: token('font.weight.medium', '400'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '14px',
	marginLeft: token('space.025', '2px'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Definition = styled.div({
	color: token('color.text.subtle', 'inherit'),
	whiteSpace: 'pre-wrap',
	overflowWrap: 'break-word',
	wordWrap: 'break-word',
});
