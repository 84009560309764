import React, { Fragment, useCallback, useEffect, useRef, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl-next';

import Button from '@atlaskit/button/standard-button';
import Popup from '@atlaskit/popup';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import InformationIcon from '@atlaskit/icon/core/migration/information--editor-panel';
import { Box, xcss } from '@atlaskit/primitives';
import Heading from '@atlaskit/heading';
import type { IconButtonProps } from '@atlaskit/button/new';
import { IconButton } from '@atlaskit/button/new';
import ChevronUpIcon from '@atlaskit/icon/utility/migration/chevron-up';
import ChevronDownIcon from '@atlaskit/icon/utility/migration/chevron-down';
import { ExitingPersistence, FadeIn } from '@atlaskit/motion';

import { useContentState } from '@confluence/content-state/entry-points/useContentState';
import { EndOfPageCard } from '@confluence/page-card/entry-points/EndOfPageCard';
import { useElementSeenCallback } from '@confluence/analytics';
import { ContentAppearanceType } from '@confluence/content-appearance';
import type { GlobalPageCard } from '@confluence/page-card';
import { PageSegmentLoadEnd } from '@confluence/browser-metrics';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import { fg } from '@confluence/feature-gating';
import { manuallyLogExpExposure } from '@confluence/feature-experiments';

import { EndOfPageRecFeedbackForm } from './EndOfPageRecFeedbackForm';
import { EndOfPageRecCarousel } from './EndOfPageRecCarousel';
import { EndOfPageRecGrid } from './EndOfPageRecGrid';
import type { EndOfPageRecommendationQuery as EndOfPageRecommendationData } from './__types__/EndOfPageRecommendationQuery';
import { END_OF_PAGE_RECOMMENDATIONS_METRIC } from './perf.config';
import { i18n } from './i18n';
import {
	EndOfPageRecContainer,
	EndOfPageRecHeader,
	HeaderFrontContainer,
	InfoContainer,
	FeedbackContainer,
	HeaderLineContainer,
	PageCardsContainer,
} from './styled';
import { EndOfPageRecContainerCohort } from './useWhichEndOfPageRecContainerToRender';

const EndOfCardWrapperStyle = xcss({
	flexBasis: '100%',
	flex: '1',
	width: '0',
});

type EndOfPageRecommendationComponentProps = {
	entityId: string;
	entityType: string;
	pageCardData: GlobalPageCard[] | undefined;
	data: EndOfPageRecommendationData | undefined;
	pageWidthType: string;
	spaceKey: string;
	containerCohort: EndOfPageRecContainerCohort;
};

const CONTROL_CARD_LIMIT = 3;
const GRID_CARD_LIMIT = 6;
const CAROUSEL_CARD_LIMIT = 9;

export const EndOfPageRecommendationComponent = ({
	entityId,
	entityType,
	pageCardData,
	data,
	pageWidthType,
	containerCohort,
}: EndOfPageRecommendationComponentProps) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isInfoOpen, setInfoOpen] = useState(false);
	const [showFeedbackForm, setFeedbackForm] = useState(false);
	const [isCollapsed, setIsCollapsed] = useState(false);
	const compRef = useRef<HTMLDivElement | null>(null);
	const infoButtonRef = useRef<HTMLElement | null>(null);

	const [, actions] = useContentState();

	const pageRecIds = useMemo(() => {
		return getPageRecInfoForAnalyticalEvents<string[]>(
			pageCardData?.map((content) => content.id),
			containerCohort,
		);
	}, [pageCardData, containerCohort]);

	const pageRecStrategy = useMemo(() => {
		return getPageRecInfoForAnalyticalEvents<string[][]>(
			data?.getRecommendedPages?.recommendedPages?.map((recs) => recs.strategy),
			containerCohort,
		);
	}, [data, containerCohort]);

	const spaceId = data?.space?.id;

	const createdDate = data?.singleContent?.metadata?.createdDate;
	const lastModifiedDate = data?.singleContent?.metadata?.lastModifiedDate;

	const tooltipOptions: IconButtonProps['tooltip'] = {
		position: 'right',
		hideTooltipOnClick: true,
	};

	const toggleInfo = useCallback(() => {
		if (!isInfoOpen) {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source: entityType,
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'endOfPageRecommendationsInfoButton',
					attributes: {
						contentId: entityId,
					},
				},
			}).fire();
		}

		setInfoOpen(!isInfoOpen);
	}, [createAnalyticsEvent, isInfoOpen, entityId, entityType]);

	const clickFeedback = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: entityType,
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'endOfPageRecommendationsFeedback',
				attributes: {
					contentId: entityId,
				},
			},
		}).fire();

		setInfoOpen(false);
		setFeedbackForm(!showFeedbackForm);
	}, [createAnalyticsEvent, showFeedbackForm, entityId, entityType]);

	const closeSurvey = useCallback(() => {
		setFeedbackForm(false);
	}, [setFeedbackForm]);

	const onObserved = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				source: entityType,
				action: 'observed',
				actionSubject: 'endOfPageRecommendations',
				attributes: {
					recommendedIds: pageRecIds,
					strategies: pageRecStrategy,
				},
				objectId: entityId,
				containerId: spaceId,
			},
		}).fire();
		manuallyLogExpExposure('confluence_related_pages_experience_exp');
	}, [createAnalyticsEvent, entityId, pageRecStrategy, pageRecIds, spaceId, entityType]);

	const onCollapseExpandClick = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				source: entityType,
				action: isCollapsed ? 'expanded' : 'collapsed',
				actionSubject: 'endOfPageRecommendations',
				attributes: {
					recommendedIds: pageRecIds,
					strategies: pageRecStrategy,
				},
				objectId: entityId,
				containerId: spaceId,
			},
		}).fire();

		setIsCollapsed(!isCollapsed);
	}, [
		createAnalyticsEvent,
		entityType,
		isCollapsed,
		pageRecIds,
		pageRecStrategy,
		entityId,
		spaceId,
	]);

	useElementSeenCallback(compRef, onObserved);

	useEffect(() => {
		if (!!pageRecIds?.length && pageRecIds?.length > 1) {
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: entityType,
					action: 'rendered',
					actionSubject: 'endOfPageRecommendations',
					attributes: {
						recommendedIds: pageRecIds,
						strategies: pageRecStrategy,
					},
					objectId: entityId,
					containerId: spaceId,
				},
			}).fire();
		}
	}, [createAnalyticsEvent, entityId, pageRecStrategy, pageRecIds, spaceId, entityType]);

	const getMaxWidth = (isDefaultWidth: boolean, isDefaultContainer: boolean): string => {
		if (isDefaultContainer) {
			if (isDefaultWidth) {
				return '760px';
			} else {
				return 'unset';
			}
		}
		return '1000px';
	};

	const maxWidth = getMaxWidth(
		JSON.parse(pageWidthType) === ContentAppearanceType.DEFAULT,
		containerCohort === EndOfPageRecContainerCohort.CONTROL,
	);

	const isNotSSRed = fg('ssr_end_of_page_recommendation')
		? !process.env.REACT_SSR && !window.__SSR_RENDERED__
		: true;

	const handleInfoButtonRef = (triggerProps) => {
		return (node) => {
			infoButtonRef.current = node;
			triggerProps.ref(node);
		};
	};

	return !!pageCardData?.length ? (
		<Fragment>
			{isNotSSRed && (actions?.addContentToBeLoaded('endOfPageRecommendation', 'loading'), null)}
			<EndOfPageRecContainer
				maxWidth={maxWidth}
				data-vc="end-of-page-recommendation-component"
				{...ssrPlaceholderIdProp}
			>
				<EndOfPageRecHeader>
					<HeaderFrontContainer>
						<Heading size="xsmall" as="h2">
							<FormattedMessage {...i18n.title} />
						</Heading>
						<Popup
							shouldRenderToParent
							onClose={toggleInfo}
							content={() => (
								<InfoContainer>
									<FormattedMessage {...i18n.info} />
									<FeedbackContainer>
										<Button onClick={clickFeedback}>
											<FormattedMessage {...i18n.feedback} />
										</Button>
									</FeedbackContainer>
								</InfoContainer>
							)}
							isOpen={isInfoOpen}
							placement="top"
							trigger={(triggerProps) => (
								<IconButton
									appearance="subtle"
									onClick={toggleInfo}
									isSelected={isInfoOpen}
									testId="eop-info-button"
									icon={InformationIcon}
									label="Info icon"
									{...triggerProps}
									ref={handleInfoButtonRef(triggerProps)}
								/>
							)}
						/>
					</HeaderFrontContainer>
					<HeaderLineContainer />
					{fg('confluence_frontend_insights_eop_collapsible') && (
						<IconButton
							appearance="subtle"
							icon={isCollapsed ? ChevronDownIcon : ChevronUpIcon}
							label={
								isCollapsed ? (
									<FormattedMessage {...i18n.expandButton} />
								) : (
									<FormattedMessage {...i18n.collapseButton} />
								)
							}
							onClick={onCollapseExpandClick}
							isTooltipDisabled={false}
							tooltip={tooltipOptions}
							testId={isCollapsed ? 'eop-expand-button' : 'eop-collapse-button'}
						/>
					)}
				</EndOfPageRecHeader>
				{isNotSSRed && fg('confluence_frontend_insights_eop_collapsible') ? (
					<ExitingPersistence appear>
						{!isCollapsed && (
							<FadeIn entranceDirection="top" exitDirection="top" duration={400}>
								{(props) => (
									<div {...props}>
										{renderCards(containerCohort, entityId, pageCardData, pageRecStrategy)}
										<div ref={compRef} />
									</div>
								)}
							</FadeIn>
						)}
					</ExitingPersistence>
				) : (
					<>
						{renderCards(containerCohort, entityId, pageCardData, pageRecStrategy)}
						<div ref={compRef} />
					</>
				)}
			</EndOfPageRecContainer>
			{showFeedbackForm && (
				<EndOfPageRecFeedbackForm
					closeSurvey={closeSurvey}
					entityId={entityId}
					recIds={pageRecIds}
					sources={pageRecStrategy}
					createdDate={createdDate}
					lastModifiedDate={lastModifiedDate}
					shouldReturnFocus={infoButtonRef}
				/>
			)}
			<PageSegmentLoadEnd key={entityId} metric={END_OF_PAGE_RECOMMENDATIONS_METRIC} />
			{isNotSSRed && (actions?.removeContentFromLoading('endOfPageRecommendation'), null)}
		</Fragment>
	) : null;
};

const getPageRecInfoForAnalyticalEvents = <T extends string[] | string[][]>(
	allPageRecs: T | undefined,
	containerCohort: EndOfPageRecContainerCohort,
): T | undefined => {
	if (!allPageRecs) {
		return;
	}

	switch (containerCohort) {
		case EndOfPageRecContainerCohort.CONTROL:
			return allPageRecs?.slice(0, CONTROL_CARD_LIMIT) as T;
		case EndOfPageRecContainerCohort.CAROUSEL:
			return allPageRecs?.slice(0, CAROUSEL_CARD_LIMIT) as T;
		case EndOfPageRecContainerCohort.GRID:
			return allPageRecs?.slice(0, GRID_CARD_LIMIT) as T;
	}
};

const renderCards = (
	containerCohort: EndOfPageRecContainerCohort,
	entityId: string,
	pageCardData: GlobalPageCard[],
	pageRecStrategy?: string[][],
): JSX.Element => {
	switch (containerCohort) {
		case EndOfPageRecContainerCohort.CAROUSEL:
			if (pageCardData.length < 4) {
				return (
					<EndOfPageRecGrid
						pageCardData={pageCardData.slice(0, GRID_CARD_LIMIT)}
						pageRecStrategy={pageRecStrategy}
						entityId={entityId}
					/>
				);
			}
			return (
				<EndOfPageRecCarousel
					pageCardData={pageCardData.slice(0, CAROUSEL_CARD_LIMIT)}
					pageRecStrategy={pageRecStrategy}
					entityId={entityId}
				/>
			);
		case EndOfPageRecContainerCohort.GRID:
			return (
				<EndOfPageRecGrid
					pageCardData={pageCardData.slice(0, GRID_CARD_LIMIT)}
					pageRecStrategy={pageRecStrategy}
					entityId={entityId}
				/>
			);
		default:
			return (
				<PageCardsContainer>
					{pageCardData.slice(0, CONTROL_CARD_LIMIT).map((node, i) => (
						<Box key={node.id} xcss={EndOfCardWrapperStyle}>
							<EndOfPageCard
								key={node.id}
								data={node}
								ranking={i}
								analyticsData={{
									source: 'endOfPageRecommendations',
									attributes: {
										strategy: pageRecStrategy ? pageRecStrategy[i] : null,
										mainContentId: entityId,
									},
								}}
							/>
						</Box>
					))}
				</PageCardsContainer>
			);
	}
};
