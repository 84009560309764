import {
	PAGE_ANALYTICS_VIEW_CHART_EXPERIENCE,
	PAGE_ANALYTICS_TOTAL_VIEWS_CARD_EXPERIENCE,
	PAGE_ANALYTICS_UNIQUE_VIEWERS_CARD_EXPERIENCE,
	PAGE_ANALYTICS_MENTIONED_USERS_CARD_EXPERIENCE,
	PAGE_ANALYTICS_VIEWERS_TABLE_EXPERIENCE,
	PAGE_ANALYTICS_MENTIONED_USERS_TABLE_EXPERIENCE,
} from '@confluence/experience-tracker';

// The order should represent the order in the UI
export enum PageAnalyticsTab {
	'Overview',
	'Mentions',
	'Links',
}

export enum PageAnalyticsLinksSubTab {
	Outbound = 'outbound',
	Inbound = 'inbound',
}

// We renamed the tab to "overview" but we want to keep the GASv3 events consistent with the old plugin which is using "views"
// Also, GASv3 event names supposed to be lowercase.
export const PageAnalyticsGASv3EventName = {
	[PageAnalyticsTab.Overview]: 'views',
	[PageAnalyticsTab.Mentions]: 'mentions',
	[PageAnalyticsTab.Links]: 'links',
};

export const PageAnalyticsSubExperiences = {
	[PageAnalyticsTab.Overview]: [
		PAGE_ANALYTICS_VIEW_CHART_EXPERIENCE,
		PAGE_ANALYTICS_TOTAL_VIEWS_CARD_EXPERIENCE,
		PAGE_ANALYTICS_UNIQUE_VIEWERS_CARD_EXPERIENCE,
		PAGE_ANALYTICS_MENTIONED_USERS_CARD_EXPERIENCE,
		PAGE_ANALYTICS_VIEWERS_TABLE_EXPERIENCE,
	],
	[PageAnalyticsTab.Mentions]: [
		// Put back during fg('confluence_analytics_send_reminder_toggle') clean-up, otherwise this won't be collected by experience tracker
		// PAGE_ANALYTICS_MENTIONED_USERS_REMINDER_EXPERIENCE,
		PAGE_ANALYTICS_MENTIONED_USERS_TABLE_EXPERIENCE,
	],
	[PageAnalyticsTab.Links]: [],
};

export const PAGE_ANALYTICS_SCREEN_ANALYTICS_SOURCE = 'analyticsPageScreen';
export const PAGE_ANALYTICS_SCREEN_ANALYTICS_LEVEL = 'page';
export const PAGE_ANALYTICS_UPSELL_SCREEN_ANALYTICS_SOURCE = 'pageAnalyticsScreen';
export const PAGE_ANALYTICS_TABS_UI_ID = 'page-analytics-tabs';
export const EXPLORE_ANALYTICS_URL =
	'https://support.atlassian.com/confluence-cloud/docs/view-insights-on-pages/';
export const UPGRADE_URL = '/admin/billing/applications/change-edition/confluence.ondemand';
export const UPSELL_LINK_ACTION_SUBJECT = 'link';
export const UPSELL_BUTTON_ACTION_SUBJECT = 'button';
export const UPSELL_LINK_ACTION_SUBJECT_ID = 'exploreAnalytics';
export const UPSELL_BUTTON_ACTION_SUBJECT_ID = 'tryItFree';
export const MENTIONED_USERS_CONTENT_TEST_ID = 'page-analytics-mentioned-users-growth-card-content';
export const TOTAL_VIEWS_CONTENT_TEST_ID = 'page-analytics-total-views';
export const UNIQUE_VIEWERS_CONTENT_TEST_ID = 'page-analytics-unique-viewers';
export const VIEWERS_TABLE_TEST_ID = 'page-analytics-viewers-table';
export const MENTIONED_USERS_TABLE_TEST_ID = 'page-analytics-mentioned-users-table';
export const VIEW_CHART_TEST_ID = 'page-analytics-view-chart';
