import type { ReactNode } from 'react';
import { defineMessages } from 'react-intl-next';
import type { MessageDescriptor } from 'react-intl-next';

import { PageAnalyticsTab } from '../PageAnalytics/pageAnalyticsConstants';

export enum TestId {
	DIALOG = 'analytics-dialog',
	// CAUTION: if you update this you'll need to update the contents of the scripts in src/ssr-scripts.
	DIALOG_SKELETON = 'analytics-dialog-skeleton',
	LINK = 'analytics-link',
	LINK_SKELETON = 'analytics-link-skeleton',
	CLOSE_BUTTON = 'dialog-close-button',
	PILLS = 'analytics-pills',
	INFO_PANEL_1 = 'analytics-info-panel-1',
	INFO_PANEL_2 = 'analytics-info-panel-2',
	CHART = 'analytics-chart',
	USER_LIST = 'analytics-user-list',
}

export const i18n = defineMessages({
	dialogContainerAriaLabel: {
		id: 'confluence-analytics.dialog.container-aria-label',
		defaultMessage: 'Confluence Analytics nonmodal dialog',
		description: 'Label announced by the screen reader when the analytics dialog opens.',
	},
	dialogLoadingText: {
		id: 'confluence-analytics.dialog.loading',
		defaultMessage: 'Loading data...',
		description: 'Text to display while the dialog is loading.',
	},
	dialogLoadingTextMentioned: {
		id: 'confluence-analytics.dialog.loading.mentioned',
		defaultMessage: 'Loading mentions data...',
		description: 'Text to display while the dialog for mentioned users data is loading.',
	},
	dialogMentionedUsersViewed: {
		id: 'confluence-analytics.dialog.mentioned-users-viewed',
		defaultMessage:
			'mentioned {seenMentionedUsersCount, plural, one {user} other {users}} viewed this page',
		description: 'Text following the fraction of how many mentioned users viewed this page.',
	},
	dialogMentionedUsersAllSeen: {
		id: 'confluence-analytics.dialog.mentioned-users-all-seen',
		defaultMessage: 'Everyone mentioned has viewed',
		description: 'Text to indicate all mentioned users have viewed a page.',
	},
	dialogProgressbarCountAriaLabel: {
		id: 'confluence-analytics.dialog.progress-bar.count-aria-label',
		defaultMessage:
			'{seenMentionedUsersCount} of {totalMentionedUsersCount} mentioned {seenMentionedUsersCount, plural, one {user} other {users}} viewed this page',
		description:
			'Label announced by the screen reader to specify the fraction of how many of the total mentioned users have seen the page.',
	},
	dialogProgressbarAriaLabel: {
		id: 'confluence-analytics.dialog.progress-bar-aria-label',
		defaultMessage: 'Mentioned users progress bar',
		description: 'Label announced by the screen reader to describe the progress bar.',
	},
	graphGranularityWeek: {
		id: 'confluence-analytics.graph.granularity.week',
		defaultMessage: 'Week of {date}',
		description:
			'Sub-text for analytics graph which describes the granularity of data being viewed.',
	},
	graphCreatedAtDateTimeLabel: {
		id: 'confluence-analytics.graph.label.created-at',
		defaultMessage: 'Created {date} at {time}',
		description: 'Label specifying the date and time at which content was created.',
	},
	graphDateToDateTimeLabel: {
		id: 'confluence-analytics.graph.label.date-to-datetime',
		defaultMessage: '{fromDate} to {toDate} ({toTime})',
		description: 'Label specifying a range of time for the graph being displayed.',
	},
	lastViewedJustNow: {
		id: 'confluence-analytics.dialog.viewers-list.viewed-just-now',
		defaultMessage: 'Just now',
		description: 'Label specifying a viewer saw the page just less than a minute ago.',
	},
	lastViewedMinutesAgo: {
		id: 'confluence-analytics.dialog.viewers-list.viewed-minutes-ago',
		defaultMessage: '{minuteCount} {minuteCount, plural, one {minute} other {minutes}} ago',
		description: 'Label specifying a viewer saw the page minutes ago, should always be plural.',
	},
	lastViewedHoursAgo: {
		id: 'confluence-analytics.dialog.viewers-list.viewed-hours-ago',
		defaultMessage: '{hourCount} {hourCount, plural, one {hour} other {hours}} ago',
		description: 'Label specifying a viewer saw the page hours ago, should always be plural.',
	},
	lastViewedDaysAgo: {
		id: 'confluence-analytics.dialog.viewers-list.viewed-days-ago',
		defaultMessage: '{dayCount} {dayCount, plural, one {day} other {days}} ago',
		description: 'Label specifying a viewer saw the page days ago, should always be plural.',
	},
	lastViewedWeeksAgo: {
		id: 'confluence-analytics.dialog.viewers-list.viewed-weeks-ago',
		defaultMessage: '{weekCount} {weekCount, plural, one {week} other {weeks}} ago',
		description: 'Label specifying a viewer saw the page weeks ago, should always be plural.',
	},
	lastViewedMonthsAgo: {
		id: 'confluence-analytics.dialog.viewers-list.viewed-months-ago',
		defaultMessage: '{monthCount} {monthCount, plural, one {month} other {months}} ago',
		description: 'Label specifying a viewer saw the page months ago, should always be plural.',
	},
	lastViewedYearsAgo: {
		id: 'confluence-analytics.dialog.viewers-list.viewed-years-ago',
		defaultMessage: '{yearCount} {yearCount, plural, one {year} other {years}} ago',
		description: 'Label specifying a viewer saw the page years ago, should always be plural.',
	},
	errorMessage: {
		id: 'confluence-analytics.dialog.loading.error-message',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: "We're having trouble loading analytics.",
		description: 'Error message to display when the dialog fails to load.',
	},
	errorSuggestion: {
		id: 'confluence-analytics.dialog.loading.error-suggestion',
		defaultMessage: 'Refresh to try again.',
		description: 'Suggested action to take when the dialog failed to load.',
	},
	viewedMentionsLabel: {
		id: 'confluence-analytics.dialog.mentions-list.viewed.label',
		defaultMessage: 'Viewed',
		description: 'Label for the list of mentioned users on a page that have since viewed the page.',
	},
	notViewedMentionsLabel: {
		id: 'confluence-analytics.dialog.mentions-list.not-viewed.label',
		defaultMessage: 'Not viewed',
		description:
			'Label for the list of mentioned users on a page that have not yet viewed the page.',
	},
	mentionedPeopleLabel: {
		id: 'confluence-analytics.dialog.mentions-list.mentioned-people.label',
		defaultMessage: 'Mentioned people',
		description:
			'Label for the list of mentioned users on a page that have viewed or not yet viewed the page.',
	},
});

export enum DialogPillName {
	VIEWS = 'views',
	MENTIONS = 'mentions',
}

export type DialogPillToPanelMapping = Record<DialogPillName, ReactNode>;

export const pillToLoadingTextI18n: Record<DialogPillName, MessageDescriptor> = {
	[DialogPillName.VIEWS]: i18n.dialogLoadingText,
	[DialogPillName.MENTIONS]: i18n.dialogLoadingTextMentioned,
};

export const dialogToPageAnalyticsTab: Record<DialogPillName, PageAnalyticsTab> = {
	[DialogPillName.VIEWS]: PageAnalyticsTab.Overview,
	[DialogPillName.MENTIONS]: PageAnalyticsTab.Mentions,
};
