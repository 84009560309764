import type { FC } from 'react';
import React, { useState, useEffect, useMemo, Fragment, useCallback } from 'react';
import idx from 'idx';
import { useQuery } from '@apollo/react-hooks';
import debounce from 'lodash/debounce';

import type { ADDoc } from '@atlaskit/editor-common/validator';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Box, xcss } from '@atlaskit/primitives';

import { ErrorDisplay, isStatusCodeError } from '@confluence/error-boundary';
import { getTranslation } from '@confluence/i18n';
import { PageSegmentLoadStart } from '@confluence/browser-metrics';
import { markErrorAsHandled } from '@confluence/graphql';
import { fg } from '@confluence/feature-gating';
import { expValEquals } from '@confluence/feature-experiments';
import { useDocChangeContext } from '@confluence/editor-features';
import { useNativeCollabState } from '@confluence/native-collab';

import { ReadTimeComponent } from './ReadTimeComponent';
import type { ContentFeatures } from './featuresExtractor';
import { extractContentFeatures } from './featuresExtractor';
import { ReadTimeQuery } from './ReadTimeQuery.graphql';
import { ReadTimeSmartsQuery } from './ReadTimeSmartsQuery.graphql';
import type { ReadTimeQuery as ReadTimeQueryResponse } from './__types__/ReadTimeQuery';
import { READ_TIME_METRIC } from './perf.config';

const wpm = 265; // words per minute, inspired by Medium.com
const minute = 60;

type ReadTimeProps = {
	contentId: string;
	isInEditor?: boolean;
};

const getAdf = (response: ReadTimeQueryResponse): ADDoc | null => {
	const representation = idx(response, (_) => _.content.nodes[0].body.dynamic.representation);
	if (representation === 'atlas_doc_format') {
		const adfString = idx(response, (_) => _.content.nodes[0].body.dynamic.value);
		if (adfString) {
			try {
				return JSON.parse(adfString);
			} catch {
				return null;
			}
		}
	}
	return null;
};

const calculateImageReadTime = (imageCount: number): number => {
	let readTime: number = 0;
	for (let i = 0; i < imageCount; i++) {
		readTime += Math.max(12 - i, 3);
	}
	return readTime;
};

const getContentFeatureExtractedEventObject = ({
	contentType,
	contentId,
	adf,
	isInEditor,
}: {
	contentType: string;
	contentId: string;
	adf: ADDoc;
	isInEditor: boolean;
}) => ({
	type: 'sendOperationalEvent',
	data: {
		source: 'confluence-frontend',
		action: 'extracted',
		actionSubject: 'contentFeature',
		objectType: contentType,
		objectId: contentId,
		attributes: {
			...extractContentFeatures(adf),
			isInEditor,
		},
	},
});

const OriginalReadTime = ({ contentId }: { contentId: string }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { data, loading, error } = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ReadTimeQuery,
		{
			variables: { contentId },
			fetchPolicy: 'cache-only',
		},
	);

	useEffect(() => {
		const adf = getAdf(data);
		if (!createAnalyticsEvent || !adf || loading || error || !contentId) {
			return;
		}
		const contentType = idx(data, (_) => _.content.nodes[0].type);
		createAnalyticsEvent(
			getContentFeatureExtractedEventObject({ contentType, contentId, adf, isInEditor: false }),
		).fire();
	}, [contentId, loading, data, error, createAnalyticsEvent]);

	const readTime = useMemo(() => {
		const adf = getAdf(data);
		if (!adf || loading || error || !contentId) {
			return null;
		}
		const features = extractContentFeatures(adf);

		return calculateEstimatedReadTime(features);
	}, [loading, error, data, contentId]);

	const locale = getTranslation().locale;
	const shouldRenderReadTime = locale && locale.substring(0, 2).toLowerCase() === 'en';

	if (readTime && shouldRenderReadTime) {
		return (
			<ReadTimeComponent
				readTime={readTime}
				pageId={contentId}
				isAbbreviatedReadTime={expValEquals(
					'cc-page-experiences-new-renderer-byline',
					'cohort',
					'test',
				)}
			/>
		);
	}

	return error ? <ErrorDisplay error={error} /> : null;
};

const SmartsReadTime = ({ contentId }: { contentId: string }) => {
	const locale = getTranslation().locale;
	const shouldRenderReadTime = locale && locale.substring(0, 2).toLowerCase() === 'en';

	const { data, loading, error } = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ReadTimeSmartsQuery,
		{
			variables: { id: contentId },
			errorPolicy: 'all',
			fetchPolicy: 'cache-first',
		},
	);

	const readTime = data?.getSmartContentFeature?.readTime;

	if (loading) {
		return null;
	}

	if (readTime == undefined || !shouldRenderReadTime || error) {
		if (isStatusCodeError(error, '403') || error?.message.includes('403')) {
			markErrorAsHandled(error);
			return null;
		}

		return (
			<Fragment>
				<OriginalReadTime contentId={contentId} />
				{error && <ErrorDisplay error={error} />}
			</Fragment>
		);
	}

	return (
		<ReadTimeComponent
			readTime={readTime}
			pageId={contentId}
			isAbbreviatedReadTime={expValEquals(
				'cc-page-experiences-new-renderer-byline',
				'cohort',
				'test',
			)}
		/>
	);
};

const editorReadTimeStyles = xcss({
	marginRight: 'space.100',
	marginTop: 'space.050',
});

/**
 * Read Time component with support for automatically recalculating and updating read time after a document change in the editor.
 */
const EditorReadTime = ({ contentId }: { contentId: string }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { subscribeDocChange, unsubscribeDocChange } = useDocChangeContext();
	const [{ collabEditProvider }] = useNativeCollabState();

	const [readTime, setReadTime] = useState<number | null>(null);

	const { data, loading, error } = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ReadTimeQuery,
		{
			variables: { contentId },
			fetchPolicy: 'cache-and-network',
		},
	);

	const calculateReadTime = useCallback((adf: ADDoc | null) => {
		if (adf) {
			const features = extractContentFeatures(adf);
			if (!features.wordCount || features.wordCount < 150) {
				return null; // Don't show the read time for live pages with little content
			}
			return calculateEstimatedReadTime(features);
		}
		return null;
	}, []);

	useEffect(() => {
		const adf = getAdf(data);
		if (!createAnalyticsEvent || !adf || loading || error || !contentId) {
			return;
		}
		const contentType = idx(data, (_) => _.content.nodes[0].type);
		createAnalyticsEvent(
			getContentFeatureExtractedEventObject({ contentType, contentId, adf, isInEditor: true }),
		).fire();
	}, [contentId, loading, data, error, createAnalyticsEvent]);

	useEffect(() => {
		const adf = getAdf(data);
		if (adf && !loading && !error && contentId) {
			setReadTime(calculateReadTime(adf));
		}
	}, [calculateReadTime, loading, error, data, contentId]);

	const debouncedCalculateReadTime = useMemo(
		() =>
			debounce(async () => {
				if (collabEditProvider) {
					const editorState = await collabEditProvider.getCurrentState();
					if (editorState?.content) {
						setReadTime(calculateReadTime(editorState.content));
					}
				}
			}, 5000),
		[collabEditProvider, calculateReadTime],
	);

	useEffect(() => {
		subscribeDocChange(debouncedCalculateReadTime);
		return () => unsubscribeDocChange(debouncedCalculateReadTime);
	}, [debouncedCalculateReadTime, subscribeDocChange, unsubscribeDocChange]);

	const locale = getTranslation().locale;
	const shouldRenderReadTime = locale && locale.substring(0, 2).toLowerCase() === 'en';

	if (readTime && shouldRenderReadTime) {
		return (
			<Box xcss={editorReadTimeStyles} testId="editor-read-time">
				<ReadTimeComponent
					readTime={readTime}
					pageId={contentId}
					isAbbreviatedReadTime
					isInEditor
				/>
			</Box>
		);
	}

	return error ? <ErrorDisplay error={error} /> : null;
};

// Calculate Read time based on:
// 1. Average reading speed 265 WPM
// 2. Image count (1 sec added for each image until first 10 images post that 3 sec for each subsequent image)
export const calculateEstimatedReadTime = (features: ContentFeatures): number =>
	Math.ceil(
		((features.wordCount / wpm) * 60 + calculateImageReadTime(features.imageCount)) / minute,
	);

export const ReadTimeLoader: FC<ReadTimeProps> = ({ contentId, isInEditor }) => {
	return (
		<>
			<PageSegmentLoadStart metric={READ_TIME_METRIC} />
			<ReadTime contentId={contentId} isInEditor={isInEditor} />
		</>
	);
};

export const ReadTime: FC<ReadTimeProps> = ({ contentId, isInEditor }) => {
	if (isInEditor) {
		return <EditorReadTime contentId={contentId} />;
	}

	if (fg('confluence_frontend_skip_smarts_read_time')) {
		return <OriginalReadTime contentId={contentId} />;
	}

	return <SmartsReadTime contentId={contentId} />;
};
