import type { FC, RefObject } from 'react';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl-next';

import AkFieldBase from '@atlaskit/form/Field';
import TextArea from '@atlaskit/textarea';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	SatisfactionSurveyComponent,
	SatisfactionSurveyModal,
} from '@confluence/satisfaction-survey';
import { useSessionData } from '@confluence/session-data';
import type { OnInteractionData } from '@confluence/satisfaction-survey';

import { FeedbackFormQuestion } from './styled';
import { i18n } from './i18n';

type EndOfPageRecFeedbackFormProps = {
	closeSurvey: () => void;
	entityId?: string;
	recIds?: string[] | undefined;
	sources?: string[][] | undefined;
	createdDate?: string | null | undefined;
	lastModifiedDate?: string | null | undefined;
	shouldReturnFocus?: boolean | RefObject<HTMLElement>;
	spaceKey?: string | undefined;
};

export const EndOfPageRecFeedbackFormModal: FC<EndOfPageRecFeedbackFormProps> = ({
	closeSurvey,
	entityId,
	recIds,
	sources,
	createdDate,
	lastModifiedDate,
	spaceKey,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [completed, setCompleted] = useState(false);
	const [dismiss, setDismiss] = useState(false);
	const secondQuestion = useRef<string>();
	const { userId } = useSessionData();
	const intl = useIntl();

	useEffect(() => {
		if (dismiss) {
			closeSurvey();
		}
	}, [dismiss, closeSurvey]);

	const secondFeedback = (
		<AkFieldBase
			name="feedBackTextTwo"
			label={
				<FeedbackFormQuestion>
					{intl.formatMessage(i18n.feedbackSecondFollowup)}
				</FeedbackFormQuestion>
			}
		>
			{({ fieldProps }) => (
				<TextArea
					{...fieldProps}
					onChange={(event) => {
						secondQuestion.current = event.target.value;
					}}
					value={secondQuestion.current}
					maxHeight="256px"
					rows={3}
				/>
			)}
		</AkFieldBase>
	);

	const handleFormData = useCallback(
		({ reaction, feedback, submitted, dismissed }: OnInteractionData) => {
			if (dismissed) {
				setDismiss(true);
			} else if (submitted) {
				if (spaceKey) {
					createAnalyticsEvent({
						type: 'sendTrackEvent',
						data: {
							action: 'submitted',
							actionSubject: 'survey',
							actionSubjectId: 'endOfPageRecsFeedbackForm',
							source: 'endOfPageSpaceSettings',
							attributes: {
								rating: reaction.value,
								questionOne: feedback,
								userId,
							},
							objectId: spaceKey,
						},
					}).fire();
				} else {
					createAnalyticsEvent({
						type: 'sendTrackEvent',
						data: {
							action: 'submitted',
							actionSubject: 'survey',
							actionSubjectId: 'endOfPageRecsFeedbackForm',
							source: 'endOfPageRecsScreen',
							attributes: {
								rating: reaction.value,
								questionOne: feedback,
								questionTwo: secondQuestion.current,
								recommendedIds: recIds,
								sources,
								userId,
								createdDate,
								lastModifiedDate,
							},
							objectId: entityId,
						},
					}).fire();
				}

				setCompleted(true);
			}
		},
		[
			setDismiss,
			createAnalyticsEvent,
			setCompleted,
			entityId,
			recIds,
			sources,
			userId,
			createdDate,
			lastModifiedDate,
			spaceKey,
		],
	);

	const reactions = [
		intl.formatMessage(i18n.terrible),
		intl.formatMessage(i18n.bad),
		intl.formatMessage(i18n.okay),
		intl.formatMessage(i18n.good),
		intl.formatMessage(i18n.great),
	];
	const questions = reactions.map(() => intl.formatMessage(i18n.feedbackFollowup));

	return (
		<SatisfactionSurveyComponent
			title={intl.formatMessage(i18n.feedbackTitle)}
			description={intl.formatMessage(i18n.feedbackDescription)}
			finalTitle={intl.formatMessage(i18n.feedbackConfirmation)}
			finalDescription={intl.formatMessage(i18n.feedbackConfirmationDescription)}
			submitButtonText={intl.formatMessage(i18n.feedbackSubmit)}
			closeButtonText={intl.formatMessage(i18n.feedbackCancel)}
			reactionTitles={reactions}
			formQuestions={questions}
			isOnFinalStage={completed}
			onInteraction={handleFormData}
			customFields={!spaceKey ? secondFeedback : undefined}
		/>
	);
};

export const EndOfPageRecFeedbackForm: FC<EndOfPageRecFeedbackFormProps> = ({
	closeSurvey,
	entityId,
	recIds,
	sources,
	createdDate,
	lastModifiedDate,
	spaceKey,
	shouldReturnFocus,
}) => {
	return (
		<div data-testid="-feedback-survey">
			<SatisfactionSurveyModal size="medium" shouldReturnFocus={shouldReturnFocus} autoFocus>
				<EndOfPageRecFeedbackFormModal
					closeSurvey={closeSurvey}
					entityId={entityId}
					sources={sources}
					recIds={recIds}
					createdDate={createdDate}
					lastModifiedDate={lastModifiedDate}
					spaceKey={spaceKey}
				/>
			</SatisfactionSurveyModal>
		</div>
	);
};
